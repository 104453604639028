import { render, staticRenderFns } from "./SoftwareDownloadsControlPanel.vue?vue&type=template&id=a53b15f4&scoped=true&"
import script from "./SoftwareDownloadsControlPanel.vue?vue&type=script&lang=ts&"
export * from "./SoftwareDownloadsControlPanel.vue?vue&type=script&lang=ts&"
import style0 from "./SoftwareDownloadsControlPanel.vue?vue&type=style&index=0&id=a53b15f4&lang=scss&scoped=true&"
import style1 from "./SoftwareDownloadsControlPanel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a53b15f4",
  null
  
)

export default component.exports