












































import {Component, Vue} from "vue-property-decorator";
import TextInput from "../form/TextInput.vue";
import FormButton from "@/components/form/Button.vue";
import VueSelect from "vue-select";
import draggable from "vuedraggable";
import axios from "axios";
import {captureException} from "@sentry/browser";
import ControlPanelDownload from "./downloads/ControlPanelDownload.vue";

@Component({
  components: {
    TextInput,
    FormButton,
    VueSelect,
    ControlPanelDownload,
    draggable,
  },
})
export default class SoftwareDownloadsControlPanel extends Vue {
  public downloadsList: any[] = [];

  public downloadName: string = "";
  public downloadNameWarning = false;
  public fileInputWarning = false;

  public editDownloadName: string = "";
  public currentEditId = -1;
  public editDownloadNameWarning = false;

  public created() {
    axios.get("/downloads/software")
      .then((result) => {
        this.downloadsList = result.data;
      });
  }

  public validateNewDownload() {
    const fileInput = this.$refs.newFileInput as HTMLInputElement;
    this.downloadNameWarning = !this.downloadName;
    this.fileInputWarning = !fileInput.value;

    return !this.downloadNameWarning && !this.fileInputWarning;
  }

  public uploadDownload() {
    if (this.validateNewDownload()) {
      const fileInput = this.$refs.newFileInput as HTMLInputElement;
      const formData = new FormData();
      formData.append("name", this.downloadName);
      formData.append("newFile", fileInput.files[0]);

      axios.post("/controlpanel/downloads/software/upload", formData).then((res) => {
        this.downloadsList.unshift(res.data);
        this.downloadName = "";
        fileInput.value = "";
        this.$modal.hide("upload-download");
      }).catch((err) => {
        captureException(err);
        alert("Something went wrong");
      });
    }
  }

  public editDownload(e: any) {
    this.editDownloadName = e.name;
    this.currentEditId = e.id;
    this.$modal.show("edit-download");
  }

  public validateEditDownload() {
    this.editDownloadNameWarning = !this.editDownloadName;

    return !this.editDownloadNameWarning;
  }

  public submitEdit() {
    const fileInput = this.$refs.editDownloadFile as HTMLInputElement;
    if (this.validateEditDownload()) {
      if (fileInput.value) {
        const formData = new FormData();
        formData.append("name", this.editDownloadName);
        formData.append("editFile", fileInput.files[0]);

        axios.patch(`/controlpanel/downloads/software/${this.currentEditId}/file`, formData).then(() => {
          const download = this.downloadsList.find((d) => d.id == this.currentEditId);
          download.name = this.editDownloadName;

          fileInput.value = "";
          this.$modal.hide("edit-download");
        });
      } else {
        axios.patch(`/controlpanel/downloads/software/${this.currentEditId}`,
          {name: this.editDownloadName}).then(() => {
          const download = this.downloadsList.find((d) => d.id == this.currentEditId);
          download.name = this.editDownloadName;

          this.$modal.hide("edit-download");
        });
      }
    }
  }

  public deleteDownload() {
    if (confirm(`Are you sure you want to delete this download? This action can not be undone.`)) {
      axios.delete("/controlpanel/downloads/software/" + this.currentEditId).then(() => {
        this.downloadsList.splice(this.downloadsList.findIndex((d) => d.id == this.currentEditId), 1);
        this.$modal.hide("edit-download");
      });
    }
  }

  public resetEditValues() {
    this.editDownloadName = "";
    this.currentEditId = -1;
  }

  get downloads() {
    return this.downloadsList;
  }

  set downloads(value: any[]) {
    const orders = value.map((download: any, index: any) => {
      return {id: download.id, order: index};
    });
    axios.patch("/controlpanel/downloads/software/order", {orders}).then(() => {
      this.downloadsList = value;
    });
  }
}
